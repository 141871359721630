import React, {useState} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Button, Col, Form, Row, Alert, Container} from "react-bootstrap";
import globalStyle from "../styles/global.module.css"
import formStyle from "./form.module.css";
import retreatEventsStyle from "./retreat-events.module.css";
import addToMailchimp from "gatsby-plugin-mailchimp";
import VideoAudioControl from "../components/videoAudioControl";

const RetreatEventsPage = ({data}) => {

  const [formState, setFormValues] = useState({email: ""});
  const [showSubscribeForm, setShowSubscribeForm] = useState(false);
  const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
  const [showMailchimpError, setMailchimpErrorMessage] = useState({show: false, message: ""});
  const [showMailchimpSuccess, setMailchimpSuccessMessage] = useState({show: false, message: ""});
  const [formResult, setFormResult] = useState({});
  const [subscribeMailchimpUrl, setSubscribeMailchimpUrl] = useState("");

  let submitUrl = '';
  const mailchimBaseUrl = "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&id=";
  const submitUrls = {
    "san-diego": `${mailchimBaseUrl}69ec151094`,
    "los-angeles": `${mailchimBaseUrl}a188c73bcb`,
    "orange-county": `${mailchimBaseUrl}ac9761aad0`,
    "san-francisco": `${mailchimBaseUrl}51f421a9bd`,
    "san-jose": `${mailchimBaseUrl}d353d92851`,
    "east-bay": `${mailchimBaseUrl}d46a1844ab`,
    "sacremento": `${mailchimBaseUrl}69087f6738`,
  };

  const selectCity = (city) => {
    submitUrl = submitUrls[city];
    setShowSubscribeForm(true);
    setSubscribeMailchimpUrl(submitUrl);
  };

  const resetFormAfterSubmit = () => {
    setFormValues({
      email: "",
    });
  };

  const handleSubscribeForm = async (e) => {
    e.preventDefault();

    try {
      const requiredFields = ["email"];

      for (const field in formState) {
        if(requiredFields.indexOf(field) !== -1) {
          if(!formState[field]) {
            setShowErrorAlert({
              show: true,
              onField: field.charAt(0).toUpperCase()+ field.slice(1)
            });

            return;
          }
        }
      }

      const result = await addToMailchimp(formState.email, {
      }, subscribeMailchimpUrl);
      setFormResult(result);

      if (result.result === 'success') {
        setShowSubscribeForm(false);
        setMailchimpSuccessMessage({
          show: true,
          message: result.msg
        });
        resetFormAfterSubmit();
      } else {
        setMailchimpErrorMessage({
          show: true,
          message: result.msg
        });
      }
    } catch (e) {
    }
  };

  const handleSubscribeFormChange = (e) => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

    return (
      <Layout className="site-content">
        <Container>

          <div className="workshop">
            <div className="title">
              <h1 className={globalStyle.entryTitle}>MOM Retreats</h1>
            </div>

            <div className="workshop-content">
              <Row className="workshopDescription">
                <Col xs={12} md={8} sm={12}>
                  <Row>
                    {data.vimeoVideos.edges.map(item => (
                      <Col id={item.node.id} className={retreatEventsStyle.retreatEventsVideo}>
                        <iframe id={item.node.id}
                          src={"https://player.vimeo.com/video/" + item.node.id + "?portrait=1;byline=0;title=0;autoplay=1;muted=1;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=163410"}
                          width="640"
                          height="360"
                          frameBorder="0"
                          allow="autoplay; fullscreen"
                          allowFullScreen
                          title="Retreat Events">
                        </iframe>
                        <VideoAudioControl></VideoAudioControl>
                      </Col>
                    ))}

                  </Row>
                </Col>

                <Col xs={12} md={4} sm={12}>
                  <h4>About Retreats</h4>
                  <div className="topicsList">
                    <ul>
                      <li>We are here to bring Moms together and support each other.</li>
                      <li>You will find information and dates for our upcoming MOM retreats below.</li>
                      <li>Our hope is you take time for yourself and enjoy an experience you will always treasure.</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className={retreatEventsStyle.retreatEventsDescription}>
                <Col>
                  <h3>Mom’s on Maternity – Expecting Mama + New Mommy/Baby Connect Retreats</h3>
                  <p>
                    WANTED! Mom’s ready to revitalize their inner goddess! Join us for a sacred soul weekend - tune-up body,
                    mind, spirit, while you tune-into your growing baby, and tune-out all the stressors in life.
                  </p>
                  <p>
                    Being a mom is hard work, we know from experience. Take a weekend to devote to yourself because you
                    show up for everyone else in your life and you deserve it!! Self care is the first step to a better you.
                    Building connection between you and your baby in utero, can foster a deeper sense of well-bing for both
                    mother and child as your little one prepares to join us on planet Earth.
                  </p>

                  <p>
                    We’ll walk you through an amazing journey of soul alignment….
                  </p>

                  <ul>
                    <li>Prenatal Yoga, Mommy and Me Yoga or Traditional Yoga</li>
                    <li>Meditations for Mom Life Situations</li>
                    <li>Nutrition for You, Your Growing Baby and Family</li>
                    <li>Gourmet Chef Meals</li>
                    <li>Massage + Light Therapy and/or Essential Oil Therapy</li>
                    <li>Sacred Vedic Fire Ceremony - Invoke Your Inner Goddess</li>
                    <li>Sound Healing</li>
                    <li>The Essence of Touch - Asking for What You Need From Your Partner</li>
                    <li>Sacred Birthing Ritual Ceremony - Preparing Spirit for Birth</li>
                  </ul>

                  <p>
                    When we get together, heal and grow as Mothers. Amazing things happen.
                  </p>

                  <h4>Stay informed about our event retreats!</h4>

                  <ul className={retreatEventsStyle.cityList}>
                    <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, retreatEventsStyle.selectCity]} onClick={() => {selectCity('san-diego')}}>San Diego</Button></li>
                    <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, retreatEventsStyle.selectCity]} onClick={() => {selectCity('los-angeles')}}>Los Angeles</Button></li>
                    <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, retreatEventsStyle.selectCity]} onClick={() => {selectCity('orange-county')}}>Orange County</Button></li>
                    <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, retreatEventsStyle.selectCity]} onClick={() => {selectCity('san-francisco')}}>San Francisco</Button></li>
                    <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, retreatEventsStyle.selectCity]} onClick={() => {selectCity('san-jose')}}>San Jose</Button></li>
                    <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, retreatEventsStyle.selectCity]} onClick={() => {selectCity('east-bay')}}>East Bay</Button></li>
                    <li><Button className={[formStyle.btnPrimary, formStyle.submitButtonOrange, retreatEventsStyle.selectCity]} onClick={() => {selectCity('sacremento')}}>Sacramento</Button></li>
                  </ul>

                  {showSubscribeForm  &&
                    <Form onSubmit={handleSubscribeForm} className={retreatEventsStyle.subscribeButton} name="subscribe" xs={12} md={12} sm={12}>
                      <Form.Row>
                        <Col xs={12} md={12} sm={12}>
                          <Form.Group as={Col} controlId="formEmail">
                            <Form.Control size="sm" value={formState.email} name="email" type="text"
                                          placeholder="Enter your email address" onChange={handleSubscribeFormChange}/>
                          </Form.Group>
                        </Col>

                        <Col xs={12} md={12} sm={12}>
                          <Button type="submit" className={[formStyle.btnPrimary, formStyle.submitButtonOrange, retreatEventsStyle.subscribeButton]}>
                            Subscribe
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  }

                  {
                    showError.show &&
                    <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
                    >{showError.onField} field should not be empty</Alert>
                  }
                  {
                    showMailchimpError.show &&
                    <Alert variant="danger" onClose={() => setMailchimpErrorMessage({show: false})} dismissible
                    >{showMailchimpError.message}</Alert>
                  }
                  {
                    showMailchimpSuccess.show &&
                    <Alert variant="success" onClose={() => setMailchimpSuccessMessage({show: false})} dismissible
                    >{showMailchimpSuccess.message}</Alert>
                  }

                </Col>
              </Row>

            </div>
          </div>
        </Container>
      </Layout>
    )
};

export const query = graphql`
  query {
    vimeoVideos: allVimeo(
      filter: {tags: {elemMatch: {tag: {eq: "retreat-events"}}}},
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }    
      }
    }
  }
`;

export default RetreatEventsPage
